<template>
  <v-card class="full-height">
    <v-card-title>{{$t('t.Disputes')}}</v-card-title>
    <v-card-text class="card-text-full-height">
      <v-row class="full-height">
        <v-col
          class="full-height pa-0"
          cols="8"
        >
          <graph
            v-if="disputeData.byDisputeReasons && disputeData.byDisputeReasons.length"
            :options="donutOptions"
            :series="donutSeries"
            type="donut"
            height="100%"
            class="full-height vertical-center"
          />
          <div
            v-else
            class="no-result"
          >{{$t('t.NoResult')}}</div>
        </v-col>
        <v-divider vertical />
        <v-col class="full-height pa-0">
          <graph
            v-if="disputeData.disputesVsTarget"
            :options="barOptions"
            :series="barSeries"
            type="bar"
            height="110%"
            width="99%"
            class="full-height vertical-center"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Graph: () => import('@/components/graph')
  },
  data () {
    return {
      disputeData: {}
    }
  },
  methods: {
    async refreshData () {
      this.disputeData = {}
      this.disputeData = (await this.$http().versionHashed().get('/core/v6/dashboard/agent/disputes/' + this.showAll)).data
    }
  },
  computed: {
    barSeries () {
      return [{
        name: this.$t('t.OnTarget'),
        data: [this.disputeData.disputesVsTarget.disputeOnTarget]
      },
      {
        name: this.$t('t.LessThanXDays', { days: 7 }),
        data: [this.disputeData.disputesVsTarget.disputeSevenDayOfTarget]
      },
      {
        name: this.$t('t.LessThanXDays', { days: 1 }),
        data: [this.disputeData.disputesVsTarget.disputeOneDayOfTarget]
      },
      {
        name: this.$t('t.BeyondTarget'),
        data: [this.disputeData.disputesVsTarget.disputeBeyondTarget]
      }]
    },
    barOptions () {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          redrawOnParentResize: false
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          horizontalAlign: 'right',
          inverseOrder: true,
          width: 160,
          formatter: (v, plot) => {
            return v + '<br /><span class="font-weight-bold">' + this.formatCurrency(this.barSeries[plot.seriesIndex].data[0]) + '</span>'
          }
        },
        tooltip: {
          y: {
            formatter: (v) => this.formatCurrency(v)
          }
        },
        xaxis: {
          categories: [this.$t('t.GraphDisputesPerTargetDate')],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          forceNiceScale: false,
          show: false
        }
      }
    },
    donutSeries () {
      return this.disputeData.byDisputeReasons?.map(r => r.amount)
    },
    donutOptions () {
      return {
        chart: {
          type: 'donut',
          animations: {
            speed: 500
          }
        },
        labels: this.disputeData.byDisputeReasons?.map(r => r.name),
        legend: {
          position: 'right',
          horizontalAlign: 'right',
          inverseOrder: false,
          formatter: (v, plot) => {
            return v + '<br /><span class="font-weight-bold">' + this.formatCurrency(this.donutSeries[plot.seriesIndex]) + '</span>'
          },
          width: 200
        },
        stroke: {
          width: 0
        },
        tooltip: {
          y: {
            formatter: (v) => this.formatCurrency(v)
          }
        }
      }
    }
  },
  watch: {
    showAll: {
      handler (v) {
        if (typeof v !== 'undefined') {
          this.refreshData()
        }
      },
      immediate: true
    }
  },
  props: {
    showAll: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.card-text-full-height
  height calc(100% - 64px)

.vertical-center
  display flex
  flex-direction column
  justify-content center

.no-result
  padding 1em
</style>
